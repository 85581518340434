import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We might have clapped for our brave heroes or our front-line warriors. But how many of us really meant it? `}</p>
    <p>{`How many people really think about them or about this service that they are doing for us which cannot be paid back? Who do you think is behind the 5.34 million recoveries (as of today-01-07-2020)? Who do you think these people are? even by knowing that this disease is dangerous to their life, they go out of their safe chambers just to do their duty. May it be the police or the housekeeping staff. All these people are our valiant heroes. My parents come into this category of FRONT LINE WARRIORS or the ones i was talking about all this while. This is one of the rare stories or more like a short narrative piece which you wouldn’t really hear of. It is about two parents who are both dedicated to their profession- TREATING THE VICTIMS of any problem (in medicinal context)`}</p>
    <p>{`“Our chief minister has announced that every private hospital cannot refuse a patient with symptoms of the COVID-19 disease,” yelled my mother just as she entered the house!`}</p>
    <p>{`I immediately understood the reason behind my mother’s shout. It was her concern that this is just going to add more pressure to my mother and also get my family into more danger only because of her (only if she gets it). This panic had continued until the main person who gets all this agitation under control came home. My father. Somehow he convinced all of us to calm down (I do not know how he does that. It is like he has magic in his voice) after this, the day goes like how it used to before this pandemic happened. Normal, no worries in anybody’s voice and the same news over and over again played in the T.V and also, the same Arnab Goswami shouting at others(in the T.V).`}</p>
    <p>{`This cycle pretty much repeated everyday. Now also...`}</p>
    <p>{`One day I went up to my parents and asked them why they still work even though they know that it is dangerous. This was their answer- “I wouldn’t have worked if I wouldn’t have gotten the gift of seeing many people’s happiness”`}</p>
    <p>{`If we get another assignment from our prime minister to clap or light candles for our heroes, I hope, after reading this small writing, you will do the assignment in a whole-hearted way so that all our prayers go to our combatants. I hope you will also understand their importance in this world and give them respect. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      